<template>
    <div class="adduser">
        <div class="Basics-form">
            <el-form
                ref="form"
                style="margin-top: 1rem; margin-bottom: 1rem"
                :model="form"
                label-width="80px"
                size="small"
                class="lab"
                label-position="left"
            >
                <el-form-item label="邮箱品牌">
                    <el-radio-group v-model="form.brand">
                        <el-radio :label="1" v-if="btnP.tmBrand">腾讯</el-radio>
                        <el-radio :label="2" v-if="btnP.aliBrand"
                            >阿里
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <div v-if="btnP.dep">
                    <el-form-item
                        :label="index == 0 ? '所属部门' : ''"
                        required
                        v-for="(item, index) in form.departmentAdminDOList"
                        :key="item.depmId"
                    >
                        <el-cascader
                            size="small"
                            :ref="'cascader' + index"
                            v-model="item.depmId"
                            :options="depmData"
                            :style="{ width: '240px' }"
                            :show-all-levels="false"
                            placeholder="请选择部门"
                            :props="{
                                checkStrictly: true,
                                expandTrigger: 'hover',
                                emitPath: false,
                                value: 'id',
                                label: 'departmentName',
                                children: 'children',
                            }"
                        >
                            <template slot-scope="{ node, data }">
                                <div @click="cascaderClick(data, index)">
                                    <span>{{ data.departmentName }}</span>
                                </div>
                            </template>
                        </el-cascader>
                        <el-button
                            v-if="index == 0"
                            @click="addDepm"
                            type="primary"
                            class="el-icon-plus"
                            circle
                            size="mini"
                        ></el-button>
                        <el-button
                            v-if="index > 0"
                            @click.prevent="removeDepm(index)"
                            class="el-icon-minus"
                            type="primary"
                            circle
                            size="mini"
                        ></el-button>
                    </el-form-item>
                </div>

                <el-form-item label="姓名" required>
                    <el-input
                        v-model="form.nickName"
                        style="width: 240px"
                        placeholder="请输入姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item label="手机号" required>
                    <el-input
                        v-model="form.userName"
                        style="width: 240px"
                        placeholder="请输入手机号"
                    ></el-input>
                </el-form-item>
                <el-form-item label="身份：" label-width="20%" required>
                    <el-radio-group v-model="form.radio">
                        <el-radio :label="1">普通成员</el-radio>
                        <el-radio :label="2" v-if="userType == 15"
                            >上级</el-radio
                        >
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="角色权限" required>
                    <el-select
                        style="width: 240px"
                        v-model="form.role"
                        clearable
                        placeholder="请选择角色权限"
                    >
                        <el-option
                            v-for="item in roleData"
                            :key="item.value"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="企业微信" required>
                    <el-input
                        v-model="form.weiXinUserId"
                        style="width: 240px"
                        placeholder="请输入企业微信"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="shut"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 10px;
                "
                size="mini"
                @click="onSubmitFun"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { roleList } from '@/api/admin/role/role.js';
import { addUser } from '@/api/admin/framework/framework.js';
import { departmentList } from '@/api/admin/framework/framework.js';
export default {
    props: ['postDemp'],
    data() {
        return {
            depmId: '',
            form: {
                nickName: '',
                userName: '',
                radio: 1,
                role: '',
                weiXinUserId: '',
                brand: 0,
                status: '',
                departmentAdminDOList: [
                    {
                        depmId: '',
                    },
                ],
            },
            roleData: [],
            depmData: [],
            btnP: [],
            userType: sessionStorage.getItem('userType'),
        };
    },

    methods: {
        // 获取角色列表
        getRole() {
            let data = {
                param: {
                    status: 1,
                },
                pageNum: 0,
                pageSize: 0,
            };
            let userType = sessionStorage.getItem('userType');
            if (userType == 8) {
                // data.param.roleTypeList = [1, 2, 3, 4, 12, 16, 17];
                this.roleData = [
                    { name: 'LM', id: 1 },
                    { name: 'SDR', id: 2 },
                    { name: 'MDR', id: 3 },
                    { name: 'AE', id: 4 },
                    { name: 'SEO管理员', id: 12 },
                    { name: '伽搜-CS', id: 16 },
                    { name: 'AE-运营', id: 17 },
                ];
            }
            if (userType == 9) {
                // data.param.roleTypeList = [2, 3, 4];
                this.roleData = [
                    { name: 'SDR', id: 2 },
                    { name: 'MDR', id: 3 },
                    { name: 'AE', id: 4 },
                ];
            }
            if (userType == 10) {
                this.roleData = [
                    { name: 'LM', id: 1 },
                    { name: 'SDR', id: 2 },
                    { name: 'MDR', id: 3 },
                    { name: 'AE', id: 4 },
                ];
                // data.param.roleTypeList = [1, 2, 3, 4];
            }
            if (userType == 15) {
                roleList(data).then((res) => {
                    if (res.code == 200) {
                        this.roleData = res.data.list;
                    }
                });
            }

            let isButArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btnArr = [];
            for (let i in isButArr) {
                if (isButArr[i].name == '新增成员') {
                    btnArr = isButArr[i].children;
                }
            }
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;

            console.log(this.btnP);
            if (btn.dep) {
                this.departmentData();
            } else {
                this.depmId = sessionStorage.getItem('departmentId');
            }
        },
        addDepm() {
            if (this.form.departmentAdminDOList.length == 5) {
                return this.$message.error('最多只能添加5个部门');
            }
            this.form.departmentAdminDOList.push({ depmId: '' });
        },
        removeDepm(index) {
            this.form.departmentAdminDOList.splice(index, 1);
            this.form.departmentAdminDOList = JSON.parse(
                JSON.stringify(this.form.departmentAdminDOList)
            );
        },
        departmentData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.depmData = this.$Tree.treeDataTranslate(res.data);
            });
        },
        cascaderClick(nodeData, index) {
            this.form.departmentAdminDOList[index].depmId = nodeData.id;
            this.form.departmentAdminDOList.forEach((item, idx) => {
                if (idx != index && item.depmId == nodeData.id) {
                    this.form.departmentAdminDOList[index].depmId = '';
                    return this.$message.error('不能选择重复部门');
                }
            });

            this.$refs['cascader' + index][0].checkedValue =
                nodeData.departmentName;
            this.$refs['cascader' + index][0].computePresentText();
            this.$refs['cascader' + index][0].toggleDropDownVisible(false);
        },
        // 添加成员
        onSubmitFun() {
            if (this.btnP.dep) {
                for (
                    let i = 0;
                    i < this.form.departmentAdminDOList.length;
                    i++
                ) {
                    if (!this.form.departmentAdminDOList[i].depmId) {
                        return this.$message.error('请选择所属部门');
                    }
                }
            }
            if (!this.form.nickName) {
                return this.$message.error('请输入姓名');
            } else if (!this.form.userName) {
                return this.$message.error('请输入手机号');
            } else if (!this.form.role) {
                return this.$message.error('请选择角色');
            } else if (!this.form.weiXinUserId) {
                return this.$message.error('请输入企业微信userid');
            } else {
                let departmentAdminDOList = [];
                this.form.departmentAdminDOList.forEach((item, index) => {
                    if (index == 0) {
                        departmentAdminDOList.push({
                            main: 1,
                            departmentId: item.depmId,
                            position: this.form.radio,
                        });
                    } else {
                        departmentAdminDOList.push({
                            departmentId: item.depmId,
                            position: this.form.radio,
                        });
                    }
                });
                let data = {
                    param: {
                        nickName: this.form.nickName,
                        userName: this.form.userName,
                        roleId: this.form.role,
                        weiXinUserId: this.form.weiXinUserId,
                        departmentAdminDOList,
                        brand: this.form.brand,
                    },
                };
                addUser(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('添加成功');
                        this.emptyData();
                        this.shut();
                    } else if (res.code == 405) {
                        this.$message.error('信息重复');
                    }
                });
            }
        },

        shut() {
            this.$emit('close');
        },

        // 清空输入框
        emptyData() {
            this.form.nickName = '';
            this.form.userName = '';
            this.form.radio = 1;
            this.form.role = '';
            this.form.weiXinUserId = '';
            this.form.brand = 0;
            this.depmId = '';
        },
    },
};
</script>
<style lang="scss" scoped>
.el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    width: 20px;
    height: 20px;
    border: 2px solid #2370eb;
    margin-left: 10px;
}
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.adduser {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .Basics {
        margin-left: 1.25rem;
        margin-right: 1rem;
        padding: 0.6rem 0;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        span {
            display: inline-block;
            width: 0.2rem;
            height: 1rem;
            background: #2370eb;
            margin-right: 0.8rem;
        }
    }
    .Basics-form {
        flex: 1;
        padding: 0 30px;
    }
    .el-form-item {
        margin-bottom: 10px;
    }
    .labellist {
        li {
            list-style: none;
            display: inline-block;
            min-width: 4rem;
            padding: 0 0.5rem;
            background: #f1f3f7;
            border-radius: 5px;
            line-height: 2rem;
            text-align: center;
            margin-right: 0.5rem;
            font-size: 0.8rem;
            cursor: pointer;
        }
        .bgcolor {
            background: #2370eb;
            color: #fff;
        }
    }
}

.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
.choiceDept {
    width: 37.5rem;
    height: 37.5rem;
    background: #fff;
    position: fixed;
    z-index: 4;
    left: 50%;
    top: 18%;
    margin-left: -18.8rem;
    border-radius: 5px;
}
.Selectedlist li {
    list-style: none;
    font-size: 0.8rem;
    min-width: 3rem;
    height: 2rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    float: left;
    background: #c5d3f9;
    margin: 0.2rem;
    padding: 0 0.5rem;
    border-radius: 5px;
    color: #fff;
}
</style>
<style scoped>
/* .el-input >>> .el-input__inner {
    border: none;
    background: #f1f3f7;
}
.el-select >>> .el-input__inner {
    border: none;
    background: #f1f3f7;
}
.is-checked >>> .el-radio__label {
    color: #2370eb;
}
.is-checked >>> .el-radio__inner {
    background: #2370eb;
    border-color: #2370eb;
}
.el-radio__input >>> .el-radio__inner:hover {
    border-color: #2370eb;
}
.el-form-item >>> .el-form-item__label {
    color: #111111;
    font-size: 0.9rem;
} */
</style>
