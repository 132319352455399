<template>
    <div class="department">
        <p class="title">
            <i class="iconfont icon-gongsi3"></i>
            <span v-if="topDepartment && topDepartment.departmentName">{{
                topDepartment.departmentName
            }}</span>
            <el-dropdown trigger.stop="click">
                <span class="el-dropdown-link" style="color: #111">
                    <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                        <el-button
                            type="text"
                            size="mini"
                            @click="() => append(data.id)"
                            style="color: #353535; font-size: 12px"
                            >新建下级部门</el-button
                        >
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <el-button
                            type="text"
                            size="mini"
                            @click="() => editdate(data)"
                            style="color: #353535; font-size: 12px"
                            >修改部门名称</el-button
                        >
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </p>
        <!-- <p
            v-if="index > 0"
            v-for="(item, index) in data"
            :key="item.id"
            :class="active == index ? 'dep active' : 'dep'"
            @click="userList(item, index)"
        >
            <span>
                {{ item.departmentName }}
            </span>
        </p> -->
        <el-tree
            :data="data"
            node-key="id"
            expand-on-click-node
            :highlight-current="true"
            :default-expand-all="true"
        >
            <!-- :default-expanded-keys="['0']"
            :default-checked-keys="['0']" -->
            <span
                style="width: 100%; position: relative; color: #111111"
                class="custom-tree-node"
                slot-scope="{ data }"
                @click="userList(data)"
            >
                <span
                    style="
                        font-size: 14px;
                        float: left;
                        width: 80%;
                        overflow: hidden;
                        color: #111;
                    "
                    >{{ data.departmentName }}
                </span>
                <el-row style="position: absolute; right: 10px; top: 0">
                    <el-col>
                        <el-dropdown trigger.stop="click">
                            <span class="el-dropdown-link" style="color: #111">
                                <i class="el-icon-more"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <el-button
                                        v-if="data.parentId == 1"
                                        type="text"
                                        size="mini"
                                        @click="() => append(data.id)"
                                        style="color: #353535; font-size: 12px"
                                        >新建下级部门</el-button
                                    >
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button
                                        type="text"
                                        size="mini"
                                        @click="() => editdate(data)"
                                        style="color: #353535; font-size: 12px"
                                        >修改部门名称</el-button
                                    >
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-button
                                        type="text"
                                        size="mini"
                                        @click="() => remove(data.id)"
                                        v-if="data.parentId != 0"
                                        style="
                                            margin-right: 10px;
                                            color: #353535;
                                            font-size: 12px;
                                        "
                                        >删除部门</el-button
                                    >
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>
                </el-row>
            </span>
        </el-tree>
        <el-dialog
            :visible.sync="dialogVisible"
            width="500px"
            :before-close="handleClose"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    {{ popupTitle }}
                </div>
            </template>
            <div v-if="addShow" style="padding: 20px 50px 80px 50px">
                <el-form label-width="80px">
                    <el-form-item label="部门名称">
                        <el-input
                            v-model="dempName"
                            placeholder="请输入部门名称"
                            size="small"
                            style="width: 300px"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div v-if="editShow" style="padding: 20px 50px 80px 50px">
                <el-form label-width="80px">
                    <el-form-item label="部门名称">
                        <el-input
                            v-model="dempName"
                            placeholder="请输入部门名称"
                            size="small"
                            style="width: 300px"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div class="bottom-but">
                <el-button
                    @click="close"
                    round
                    style="
                        padding: 7px 20px;
                        width: 80px;
                        height: 26px;
                        border-radio: 26px;
                    "
                    size="mini"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    style="
                        margin-right: 15px;
                        background: #2370eb;
                        padding: 7px 20px;
                        width: 80px;
                        height: 26px;
                        border-radio: 26px;
                    "
                    round
                    size="mini"
                    @click="Operation"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    addDepartment,
    editDepartment,
    removeDepartment,
} from '@/api/admin/framework/framework.js';
import { departmentList } from '@/api/admin/framework/framework.js';
export default {
    data() {
        return {
            id: '',
            data: [],
            list: '',
            dialogVisible: false,
            popupTitle: '',
            addShow: false,
            editShow: false,
            dempName: '',
            active: 0,
            btnP: {},
            topDepartment: {},
            treeRow: {},
        };
    },
    mounted() {},
    methods: {
        // 部门列表
        departmentData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.topDepartment = res.data[0];
                this.data = res.data.splice(1, res.data.length - 1);
                this.data = this.$Tree.treeDataTranslateAudit(
                    this.data,
                    'id',
                    'parentId'
                );
                if (this.btnP.depNav) {
                    this.userList(this.treeRow);
                } else {
                    this.userList(
                        sessionStorage.getItem('departmentId'),
                        this.active
                    );
                }
            });
        },
        getData(btnP) {
            console.log(btnP);
            this.btnP = btnP;
            this.departmentData();
            // if (btnP.depNav) {
            //     this.departmentData();
            // } else {
            //     console.log(sessionStorage.getItem('departmentId'));
            //     // 获取本部门成员
            //     this.departmentData(sessionStorage.getItem('departmentId'));
            // }
        },
        // 添加下级部门
        append(data) {
            this.id = data;
            this.dialogVisible = true;
            this.popupTitle = '添加部门';
            this.addShow = true;
            this.editShow = false;
            this.dempName = '';
        },
        // 修改部门
        editdate(data) {
            this.id = data.id;
            this.dialogVisible = true;
            this.popupTitle = '修改部门';
            this.addShow = false;
            this.editShow = true;
            this.dempName = data.departmentName;
        },
        Operation() {
            if (this.addShow) {
                let data = {
                    param: {
                        departmentName: this.dempName,
                        parentId: this.id,
                    },
                };
                addDepartment(data).then((res) => {
                    // console.log(res);
                    if (res.code == 200) {
                        this.$message.success('添加成功');
                        this.departmentData();
                        this.close();
                    }
                });
            } else if (this.editShow) {
                let data = {
                    param: {
                        departmentName: this.dempName,
                        id: this.id,
                    },
                };
                editDepartment(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('修改成功');
                        this.departmentData();
                        this.close();
                    }
                });
            }
        },
        close() {
            this.dialogVisible = false;
        },
        // 删除
        remove(id) {
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let data = {
                        param: {
                            id,
                        },
                    };
                    // console.log(data);
                    removeDepartment(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('删除成功');
                            this.departmentData();
                            this.userList(this.topDepartment);
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    });
                });
        },
        handleClose() {},
        // 点击获取成员
        userList(row, index) {
            this.treeRow = row;
            if (this.btnP.depNav) {
                this.active = index;
                this.$emit('dempId', row.id, row);
            } else {
                this.$emit('dempId', row);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.department {
    width: 160px;
    height: 100%;
    .title {
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
        padding: 18px 10px 18px 12px;
        display: flex;
        > i {
            font-size: 12px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: #ffbc42;
            display: inline-block;
            color: #fff;
            font-weight: normal;
            text-align: center;
            line-height: 18px;
            margin-right: 8px;
        }
        span {
            flex: 1;
        }
    }
    .dep {
        width: 144px;
        height: 32px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 1px;
        box-sizing: border-box;
        padding-left: 14px;
        line-height: 32px;
        cursor: pointer;
        margin-left: 8px;
    }
    .dep + .dep {
        margin-top: 12px;
    }
    .dep.active {
        background: #f0f4ff;
        color: #4a84ff;
    }
}
.bottom-but {
    width: 100%;
    height: 48px;
    background: #fff;
    display: flex;
    padding: 0;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e5e5e5;
}
</style>
<style lang="scss">
.department .el-tree-node__content {
    line-height: 40px !important;
    height: 40px;
}
</style>
