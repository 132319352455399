var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"member"},[_c('div',{staticClass:"title"},[_c('p',[_vm._v(" "+_vm._s(_vm.depmName || '伽搜科技')+" ")]),(_vm.btnP.add)?_c('el-button',{staticStyle:{"background":"#fff","border-radius":"2px","padding":"0 12px 0 12px","height":"24px","color":"#333333","border-color":"#d9d9d9","font-size":"12px","float":"right"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.onAdd()}}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v(" 新建成员")]):_vm._e()],1),_c('div',{staticClass:"form"},[_c('el-table',{ref:"multipleTable",staticClass:"customer-table",attrs:{"data":_vm.tableData,"stripe":"","tooltip-effect":"dark","row-style":{ height: '3.4rem' },"cell-style":{ padding: '0px' },"height":"100%","header-cell-style":{
                background: '#F9F9F9',
                color: '#353535',
                'font-size': '0.8rem',
                'font-weight': 'bold',
            }},on:{"row-click":_vm.clickRow}},[_c('el-table-column',{attrs:{"prop":"nickName","label":"成员","show-overflow-tooltip":"","width":"60"}}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"prop":"userName","label":"手机号","show-overflow-tooltip":"","width":"110"}}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"label":"所属部门","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.depmName)?_c('div',[_vm._v(_vm._s(_vm.depmName))]):_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},_vm._l((scope.row
                                    .departmentAdminDOList),function(item){return _c('p',{key:item.id,staticStyle:{"line-height":"2"}},[_vm._v(" "+_vm._s(item.departmentName)+" ")])}),0),_c('div',[_vm._v(" "+_vm._s(_vm.Formatter(scope.row))+" ")])])]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"prop":"roleName","label":"角色","show-overflow-tooltip":"","width":"100"}}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"prop":"roleName","label":"状态","show-overflow-tooltip":"","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status == 0)?_c('span',{staticStyle:{"color":"#b1b1b1"}},[_vm._v("禁用")]):_vm._e(),(scope.row.status == 1)?_c('span',{staticStyle:{"color":"#353535"}},[_vm._v("启用")]):_vm._e(),(scope.row.status == 2)?_c('span',{staticStyle:{"color":"#353535"}},[_vm._v("已离职")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"20"}}),_c('el-table-column',{attrs:{"prop":"cooperationTimeEnd","width":"100","fixed":"right","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.btnP.edit && _vm.btnP.depNav)?_c('el-button',{staticStyle:{"font-size":"0.75rem","color":"#2370eb"},attrs:{"type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.onEdit(scope.row)}}},[_vm._v(" 编辑 ")]):_vm._e(),(
                            _vm.btnP.edit &&
                            !_vm.btnP.depNav &&
                            scope.row.departmentAdminDOList[0].position ==
                                1 &&
                            scope.row.roleId != 5 &&
                            scope.row.roleId != 19
                        )?_c('el-button',{staticStyle:{"font-size":"0.75rem","color":"#2370eb"},attrs:{"type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.onEdit(scope.row)}}},[_vm._v(" 编辑 ")]):_vm._e(),(_vm.btnP.account && _vm.btnP.depNav)?_c('el-button',{staticStyle:{"font-size":"0.75rem"},attrs:{"type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.handleDelete(scope.row)}}},[(
                                scope.row.status == 1 && scope.row.type != 2
                            )?_c('span',{staticStyle:{"color":"#d0021b"}},[_vm._v("禁用")]):_vm._e(),(scope.row.status == 0)?_c('span',{staticStyle:{"color":"#03a1ba"}},[_vm._v("启用")]):_vm._e()]):_vm._e(),(
                            _vm.btnP.account &&
                            !_vm.btnP.depNav &&
                            scope.row.departmentAdminDOList[0].position ==
                                1 &&
                            scope.row.roleId != 5 &&
                            scope.row.roleId != 19
                        )?_c('el-button',{staticStyle:{"font-size":"0.75rem"},attrs:{"type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.handleDelete(scope.row)}}},[(
                                scope.row.status == 1 && scope.row.type != 2
                            )?_c('span',{staticStyle:{"color":"#d0021b"}},[_vm._v("禁用")]):_vm._e(),(scope.row.status == 0)?_c('span',{staticStyle:{"color":"#03a1ba"}},[_vm._v("启用")]):_vm._e()]):_vm._e()]}}])})],1)],1),_c('div',{staticClass:"page"},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage,"page-size":_vm.pagesize,"layout":"total,prev, pager, next, jumper","total":_vm.total,"prev-text":"上一页","next-text":"下一页"},on:{"current-change":_vm.handleCurrentChange,"size-change":_vm.handleSizeChange,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1),_c('el-drawer',{staticStyle:{"border":"none","outline":"none"},attrs:{"visible":_vm.drawer,"direction":_vm.direction,"before-close":_vm.handleClose,"size":"30rem"},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('template',{slot:"title"},[_c('div',{staticStyle:{"flex":"1","display":"flex","align-items":"center","color":"#101010"}},[_c('span',{staticClass:"vertical"}),_vm._v(" 新增成员 ")])]),_c('AddUser',{ref:"adduser",on:{"close":_vm.handleClose}})],2),_c('el-drawer',{staticStyle:{"border":"none","outline":"none"},attrs:{"visible":_vm.drawerEdit,"direction":_vm.direction,"before-close":_vm.handleClose,"size":"30rem"},on:{"update:visible":function($event){_vm.drawerEdit=$event}}},[_c('template',{slot:"title"},[_c('div',{staticStyle:{"flex":"1","display":"flex","align-items":"center","color":"#101010"}},[_c('span',{staticClass:"vertical"}),_vm._v(" 编辑成员 ")])]),_c('EditUser',{ref:"editUser",on:{"close":_vm.handleClose}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }