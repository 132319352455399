<template>
    <div class="member">
        <div class="title">
            <p>
                {{ depmName || '伽搜科技' }}
            </p>
            <el-button
                v-if="btnP.add"
                type="primary"
                @click="onAdd()"
                size="small"
                style="
                    background: #fff;
                    border-radius: 2px;
                    padding: 0 12px 0 12px;
                    height: 24px;
                    color: #333333;
                    border-color: #d9d9d9;
                    font-size: 12px;
                    float: right;
                "
            >
                <i class="el-icon-plus"></i>
                新建成员</el-button
            >
        </div>
        <div class="form">
            <el-table
                ref="multipleTable"
                :data="tableData"
                stripe
                tooltip-effect="dark"
                class="customer-table"
                @row-click="clickRow"
                :row-style="{ height: '3.4rem' }"
                :cell-style="{ padding: '0px' }"
                height="100%"
                :header-cell-style="{
                    background: '#F9F9F9',
                    color: '#353535',
                    'font-size': '0.8rem',
                    'font-weight': 'bold',
                }"
            >
                <el-table-column
                    prop="nickName"
                    label="成员"
                    show-overflow-tooltip
                    width="60"
                >
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column
                    prop="userName"
                    label="手机号"
                    show-overflow-tooltip
                    width="110"
                >
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column label="所属部门" width="80">
                    <template slot-scope="scope">
                        <div v-if="depmName">{{ depmName }}</div>
                        <el-tooltip
                            v-else
                            class="item"
                            effect="dark"
                            placement="top"
                        >
                            <div slot="content">
                                <p
                                    style="line-height: 2"
                                    v-for="item in scope.row
                                        .departmentAdminDOList"
                                    :key="item.id"
                                >
                                    {{ item.departmentName }}
                                </p>
                            </div>
                            <div>
                                {{ Formatter(scope.row) }}
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column
                    prop="roleName"
                    label="角色"
                    show-overflow-tooltip
                    width="100"
                >
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column
                    prop="roleName"
                    label="状态"
                    show-overflow-tooltip
                    width="60"
                >
                    <template slot-scope="scope">
                        <span
                            v-if="scope.row.status == 0"
                            style="color: #b1b1b1"
                            >禁用</span
                        >
                        <span
                            v-if="scope.row.status == 1"
                            style="color: #353535"
                            >启用</span
                        >
                        <span
                            v-if="scope.row.status == 2"
                            style="color: #353535"
                            >已离职</span
                        >
                    </template>
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column
                    prop="cooperationTimeEnd"
                    width="100"
                    fixed="right"
                    label="操作"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            style="font-size: 0.75rem; color: #2370eb"
                            @click.stop="onEdit(scope.row)"
                            v-if="btnP.edit && btnP.depNav"
                        >
                            编辑
                        </el-button>

                        <el-button
                            type="text"
                            style="font-size: 0.75rem; color: #2370eb"
                            @click.stop="onEdit(scope.row)"
                            v-if="
                                btnP.edit &&
                                !btnP.depNav &&
                                scope.row.departmentAdminDOList[0].position ==
                                    1 &&
                                scope.row.roleId != 5 &&
                                scope.row.roleId != 19
                            "
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            style="font-size: 0.75rem"
                            @click.stop="handleDelete(scope.row)"
                            v-if="btnP.account && btnP.depNav"
                        >
                            <span
                                style="color: #d0021b"
                                v-if="
                                    scope.row.status == 1 && scope.row.type != 2
                                "
                                >禁用</span
                            >
                            <span
                                style="color: #03a1ba"
                                v-if="scope.row.status == 0"
                                >启用</span
                            >
                        </el-button>
                        <el-button
                            type="text"
                            style="font-size: 0.75rem"
                            @click.stop="handleDelete(scope.row)"
                            v-if="
                                btnP.account &&
                                !btnP.depNav &&
                                scope.row.departmentAdminDOList[0].position ==
                                    1 &&
                                scope.row.roleId != 5 &&
                                scope.row.roleId != 19
                            "
                        >
                            <span
                                style="color: #d0021b"
                                v-if="
                                    scope.row.status == 1 && scope.row.type != 2
                                "
                                >禁用</span
                            >
                            <span
                                style="color: #03a1ba"
                                v-if="scope.row.status == 0"
                                >启用</span
                            >
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                layout="total,prev, pager, next, jumper"
                :total="total"
                prev-text="上一页"
                next-text="下一页"
            ></el-pagination>
        </div>
        <!-- 添加部门成员 -->
        <el-drawer
            style="border: none; outline: none"
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleClose"
            size="30rem"
        >
            <template slot="title">
                <div
                    style="
                        flex: 1;
                        display: flex;
                        align-items: center;
                        color: #101010;
                    "
                >
                    <span class="vertical"></span>
                    新增成员
                </div>
            </template>
            <AddUser ref="adduser" @close="handleClose" />
        </el-drawer>
        <!-- x修改 -->
        <el-drawer
            style="border: none; outline: none"
            :visible.sync="drawerEdit"
            :direction="direction"
            :before-close="handleClose"
            size="30rem"
        >
            <template slot="title">
                <div
                    style="
                        flex: 1;
                        display: flex;
                        align-items: center;
                        color: #101010;
                    "
                >
                    <span class="vertical"></span>
                    编辑成员
                </div>
            </template>
            <EditUser ref="editUser" @close="handleClose" />
        </el-drawer>
    </div>
</template>
<script>
import { userList, statusUser } from '@/api/admin/framework/framework.js';
import AddUser from './addMenber.vue';
import EditUser from './editMember.vue';
export default {
    props: ['id', 'departmentName'],
    components: {
        AddUser,
        EditUser,
    },
    data() {
        return {
            tableData: [],
            dempId: '',
            currentPage: 1,
            pagesize: 20,
            total: 0,
            direction: 'rtl',
            drawer: false,
            drawerEdit: false,
            postDemp: '',
            postEidt: '',
            depmName: '',
            btnP: {},
            name: '',
        };
    },
    watch: {
        id() {
            this.dempId = this.id;
            if (this.dempId == 1) {
                this.dempId = '';
            }

            this.getuserList(this.dempId);
        },
        departmentName() {
            this.depmName = this.departmentName;
        },
    },
    mounted() {
        if (this.dempId == 1) {
            this.dempId = '';
        }
        this.currentPage = 1;
        this.getuserList(this.dempId);
    },
    methods: {
        clickRow() {},
        // 点击获取成员
        getuserList(id, name) {
            this.name = name;
            let isButArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in isButArr) {
                btn['' + isButArr[i].route] = true;
            }
            this.btnP = btn;

            console.log(this.btnP);
            if (btn.dep) {
                this.departmentData();
            } else {
                this.depmId = sessionStorage.getItem('departmentId');
            }
            let data = {
                param: {
                    name,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (id != 1) {
                data.param.id = id;
            }
            userList(data).then((res) => {
                this.tableData = res.data.list;
                this.total = res.data.total;
                // this.departmentName = this.Formatter(res.data.list[0]);
            });
        },
        Formatter(row) {
            console.log(row);
            let str = '';
            if (row && row.departmentAdminDOList) {
                for (let i in row.departmentAdminDOList) {
                    if (row.departmentAdminDOList[i].main == 1) {
                        str = row.departmentAdminDOList[i].departmentName;
                    }
                }
            }
            return str;
        },
        // 上、下一页
        handleSizeChange(val) {
            this.pagesize = val;
            this.getuserList(this.dempId);
        }, // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getuserList(this.dempId);
        },
        // 新增成员
        onAdd() {
            this.drawer = true;
            setTimeout(() => {
                this.$refs.adduser.getRole();
            }, 100);
        },
        // 编辑成员
        onEdit(row) {
            this.drawerEdit = true;
            setTimeout(() => {
                this.$refs.editUser.getRole(row);
            }, 100);
        },
        // 禁用启用
        handleDelete(row) {
            let data = {
                param: {
                    adminIdList: [row.id],
                },
            };
            if (row.status == 1) {
                data.param.status = 0;
                this.$confirm('禁用后当前账号不可登录，是否禁用', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        statusUser(data).then((res) => {
                            if (res.code == 200) {
                                this.getuserList(this.dempId);
                                this.$message.success('禁用成功');
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消禁用',
                        });
                    });
            } else {
                data.param.status = 1;
                statusUser(data).then((res) => {
                    if (res.code == 200) {
                        this.getuserList(this.dempId);
                        this.$message.success('启用成功');
                    }
                });
            }
        },
        // 关闭弹窗
        handleClose() {
            this.drawer = false;
            this.drawerEdit = false;
            this.getuserList(this.dempId, this.name);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.member {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .title {
        height: 50px;
        line-height: 50px;
        display: flex;
        align-items: center;
        padding: 0 20px;

        p {
            flex: 1;
            font-size: 14px;
            font-weight: 600;
        }
    }
    .form {
        flex: 1;
    }
    .page {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }
}
</style>
