<template>
    <div class="framework_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 215px"
                        placeholder="请输入姓名/手机号"
                        v-model="name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="searchGet()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                </p>
            </div>
        </div>
        <div class="framework">
            <div class="department-box" v-show="btnP.depNav">
                <Department ref="department" @dempId="dempId" />
            </div>
            <div class="user-box" ref="userbox">
                <Member
                    :id="id"
                    ref="member"
                    :departmentName="departmentName"
                />
            </div>
        </div>
    </div>
</template>
<script>
import Department from './department/department.vue';
import Member from './member/member.vue';

export default {
    components: {
        Department,
        Member,
    },
    data() {
        return {
            id: '',
            name: '',
            departmentName: '',
            btnP: {},
        };
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            if (btn.depNav) {
                this.$refs.userbox.style.width = 'calc(100% - 172px)';
            }
            this.getTableData();
        },
        dempId(data, nameData) {
            this.id = data;
            this.departmentName = nameData.departmentName;
        },
        getTableData() {
            setTimeout(() => {
                this.$refs.department.getData(this.btnP);
            }, 0);
        },
        searchGet() {
            setTimeout(() => {
                this.$refs.member.getuserList(this.id, this.name);
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.framework_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                display: flex;
                align-items: center;
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
            .tab_box {
                display: flex;
                width: 100px;
                height: 32px;
                background: #edf1f3;
                box-shadow: 0px 2px 4px 0px rgba(237, 241, 243, 0.6);
                border-radius: 2px;
                font-size: 12px;
                font-weight: 500 !important;
                align-items: center;
                .iconfont {
                    font-size: 20px;
                    color: #8292b9;
                    box-shadow: 0px 2px 4px 0px rgba(237, 241, 243, 0.6);
                    border-radius: 50;
                    margin-left: 6px;
                    margin-right: 8px;
                }
            }
        }
    }
    .framework {
        width: 100%;
        height: 100%;
        display: flex;
        .department-box {
            width: 160px;
            height: 100%;
            background: #fff;
            margin-right: 12px;
        }
        .user-box {
            width: 100%;
            background: #fff;
        }
    }
}
</style>
