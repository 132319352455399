import http from '@/utils/http.js'

// 获取部门列表
export function roleList(data) {
    return http({
        url: "/jasoboss/role/list",
        method: "post",
        data
    })
}

// 获取所有菜单列表
export function adminMenuList(data) {
    return http({
        url: "/jasoboss/menu/partner/list",
        method: "post",
        data
    })
}
// 修改角色

export function roleUpdate(data) {
    return http({
        url: "/jasoboss/role/update",
        method: "post",
        data
    })
}
// 单个角色

export function roleGet(data) {
    return http({
        url: "/jasoboss/role/get",
        method: "post",
        data
    })
}
// 新增

export function addRole(data) {
    return http({
        url: "/jasoboss/role/add",
        method: "post",
        data
    })
}
// 删除
export function deleteRole(data) {
    return http({
        url: "/jasoboss/role/delete",
        method: "post",
        data
    })
}
// 单个成员
export function roleMemberGet(data) {
    return http({
        url: "/jasoboss/admin/view",
        method: "post",
        data
    })
}